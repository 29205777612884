html, body {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
    margin: 0;
    padding: 0;
    background-color: #1A1B1F;
    color: white;
}

.header--main {
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    background-color: white;
    color: black;
    border-radius: 0px 0px 20px 20px;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
}

.gallery--main {
    margin: 0 auto;
    width: 80%;
}

.gallery--title {
    font-size: 30px;
    font-weight: bold;
    margin-top: 5px;
    margin-left: 10px;
}

.gallery--videos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 5px;
}

.about--main {
    /* border: 1px solid white; */
    padding: 5px;
    margin-top: 80px;
    margin-left: 10px;
    margin-right: 10px;
}

.about--title {
    text-align: left;
    font-size: 20px;
    font-weight: bold;
}

.about--ending {
    text-align: right;
    margin-right: 10px;
    margin-top: 5px;
    font-weight: bold;
}

.video--main {
    border: 1px solid white;
    width: calc((100% - 45px) / 2);
    justify-content: center;
    text-align: center;
    background-color: rgb(76, 109, 140);
    margin: 10px;
}

@media screen and (max-width: 540px) {
    .video--main {
        width: 432px;
    }
}

.video--link {
    text-decoration: none;
    color: white;
}

.video--title {
    margin-top: 5px;
    font-weight: bold;
    font-size: 20px;
}

.video--route {
    margin-top: 5px;
}

.video--route-dep-arr {
    margin-top: 5px;
}

.video--plane {
    margin-top: 5px;
    margin-bottom: 5px;
}

.video--plane-airline {
    margin-left: 5px;
    border-left: 1px solid white;
    padding-left: 5px;
}

.map {
    height: 300px;
    width: auto;
}

.map .leaflet-container {
    height: 100%;
}

.map-container {
    z-index: 0;
}